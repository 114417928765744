/* .content {
  background-color: #1E1E2F;
} */

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.fs-1 {
  font-size: 1rem !important;
}

.fs-1-5 {
  font-size: 1.5rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

@media (min-width: 1200px) {
  #mobile-menu {
    display: none !important;
  }
  .main-panel > .content {
    padding-top: 28px !important;
  }
}

.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  overflow: hidden;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1d8cf8;
  background-color: #1d8cf8;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #cad1d7;
}

.togglebutton.switch-change-color {
  justify-content: space-evenly !important;
}
.togglebutton.switch-change-color .label-switch {
  left: 0 !important;
  font-size: 0.9rem !important;
}

.shopbanner,
.white-content .shopbanner {
  background-image: url('./assets/img/banners/shopbanner.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  min-height: 30vh !important;
}

/* pack card */

.pack.card:before {
  z-index: 10;
  text-align: center;
  line-height: 0;
}

/* .pack.pack-spotlight.card:before {
  content: '300%';
  font-size: 4rem;
  transform: rotate(354deg);
}

.pack.pack-card-3:before {
  content: '125%';
  font-size: 1.5rem;
}

.pack.pack-card-4:before {
  content: '150%';
  font-size: 2rem;
}

.pack.pack-card-5:before {
  content: '200%';
  font-size: 3rem;
} */

.pack .card-footer .amount {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount .text-success {
  font-weight: bold;
}

.amount .text-muted {
  font-size: 1rem;
}

.text-muted img {
  filter: grayscale(1);
}

.pack .pack-img {
  width: 150px;
}

.pack-spotlight .pack-img {
  width: 450px;
}

.pack-spotlight .card-footer .amount .coin {
  margin: 0 5px;
}

.extra {
  transform: translate3d(10px, 10px, 10px);
  width: 100%;
}

/* .pack .extra .badge { */
.badge {
  padding: 0.35rem 0.6rem;
  font-size: 0.8rem;
}

/* end pack card */

/* Price button */

.pack-price {
  position: relative;
  cursor: pointer;
  min-width: fit-content;
}

.pack-price:before {
  content: '';
  z-index: 1;
  position: absolute;
  display: block;
  width: 80%;
  height: 70%;
  top: 15%;
  left: 10%;
  transition: 0.3s opacity ease-in-out;
  filter: blur(15px);
  opacity: 0;
}
.pack-1,
.pack-1:hover:before {
  /* background: linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%); */
  background: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
}
.pack-2,
.pack-2:hover:before {
  /* background: linear-gradient(to top, #feada6 0%, #f5efef 100%); */
  background: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
}
.pack-3,
.pack-3:hover:before {
  /* background: linear-gradient(to top, #209cff 0%, #68e0cf 100%); */
  background: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
}
.pack-4,
.pack-4:hover:before {
  background: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
}
.pack-5,
.pack-5:hover:before {
  background: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
  /* background: linear-gradient(-225deg, #5271c4 0%, #b19fff 48%, #eca1fe 100%); */
}

.pack-price:hover:before {
  opacity: 1;
  transition: 0.3s opacity ease-in-out;
  filter: blur(40px);
}

.pack-price:after {
  content: '';
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: rgba(235, 235, 235, 1);
  font-weight: bold;
  z-index: 5;
  position: absolute;
  display: block;
  /* border-radius: 180px; */
  width: 92%;
  height: 80%;
  top: 10%;
  left: 4%;
  /* background-color: rgb(19, 20, 22);  */
}

/* end price button */

.paypal-card {
  margin-bottom: 0;
}

/* connected dots */

a.simple-text.logo-mini {
  overflow: visible;
}

.ring-container {
  position: relative;
}

.badge-p1 {
  background-image: url('./assets/img/premium/p1.png');
}

.badge-p2 {
  background-image: url('./assets/img/premium/p2.png');
}

.badge-p3 {
  background-image: url('./assets/img/premium/p3.png');
}

.badge-p4 {
  background-image: url('./assets/img/premium/p4.png');
}

.ringring.ringring-p1 {
  border-color: rgba(241, 203, 49, 1);
}

.ringring.ringring-p2 {
  border-color: rgba(214, 51, 40, 1);
}

.ringring.ringring-p3 {
  border-color: rgba(219, 47, 250, 1);
}

.ringring.ringring-p4 {
  border-color: rgba(90, 191, 154, 1);
}

.circle {
  width: 15px;
  height: 15px;
  /* background-color: #62bd19; */
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 20px;
}

.ringring {
  border: 3px solid;
  border-radius: 30px;
  height: 35px;
  width: 35px;
  position: absolute;
  left: 10px;
  top: 10px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

/* end connected dots */

.card-category {
  font-size: 1rem;
}

/* charts */

.card.height-max {
  height: calc(100% - 30px);
}

.card.card-chart.card-chart-pie .chart-area {
  max-width: 75%;
}

.card-stats .info-icon i {
  padding: 11px 12px !important;
}

.card-stats {
  height: calc(100% - 30px);
}

body {
  color: #fff;
}

body.white-content {
  color: #525f7f;
}

.card-footer .pack-price {
  text-align: right;
  font-size: 2rem;
  color: #fff;
  display: inline-block;
  padding: 0.5rem;
  border: 3px solid;
  border-top-left-radius: 1rem;
  border-right: none;
  border-bottom: none;
}

.pack .card-footer,
.monthly-pass .card-footer {
  padding: 0;
}

.mw-50 {
  max-width: 50%;
}

/* Exchange */

#exchange .input-group-append .input-group-text {
  padding: 5px 10px 5px 10px;
}

#exchange .input-group-append.shopcoin-menu {
  cursor: not-allowed;
}

#exchange .input-group-append#coins-menu {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

#exchange .input-group-append#coins-menu:hover {
  background: #1d8cf8 !important;
}

#exchange .input-group .form-control:first-child {
  text-align: right;
}

.tooltip.show.bs-tooltip-auto {
  top: -5px !important;
}

#exchange input,
#exchange button {
  font-size: 1rem;
}

#exchange .alert .coin {
  margin: 0 5px;
}

/* end Exchange */
.tooltip-inner {
  display: flex;
  align-items: center;
}
.tooltip-inner .coin,
#exchange button .coin {
  margin-left: 5px;
  margin-right: 5px;
}
.card-body {
  font-size: 1rem;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* .pack-spotlight .card-body {
  justify-content: flex-start !important;
} */

.pack-spotlight a {
  color: #fff;
}

.fixed-plugin .icon-palette {
  color: #fff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
  font-size: 2rem;
}

.fixed-plugin .dropdown-menu {
  width: 230px;
  padding: 1rem 0;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.gap-2 {
  gap: 2rem;
}
.gap-1 {
  gap: 1rem;
}
.gap-10 {
  gap: 10px;
}
.cards,
.monthly-pass.card {
  height: 100%;
  min-height: 300px;
}
.pack.card {
  margin-bottom: 0;
  flex: 1 1 200px;
}
.pack.card,
.pack.card .amount {
  font-size: 1.5rem;
}

.pack.card hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pack.card .card-body {
  /* padding: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sidebar .logout {
  position: absolute;
  bottom: 0;
  padding-bottom: 0.5rem;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.coin {
  width: 24px;
  display: inline;
}

.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
  font-size: 1rem;
  text-transform: capitalize;
}

.sidebar .logo,
.off-canvas-sidebar .logo {
  max-height: 90px;
}

.sidebar .username {
  display: flex;
  text-transform: capitalize;
  align-items: center;
}

img.ornament {
  max-height: 30px;
}

.username:first-child img {
  margin-right: 5px;
}
.username:last-child img {
  margin-left: 5px;
}

.modal.show {
  background: rgba(0, 0, 0, 0.5);
}

/* items */

.card-skin {
  flex: 1 0 300px;
  margin: 0.5rem 0.25em;
  align-items: center;
}

.card-skin .card-body {
  min-height: 200px;
}

.card-skin .btn,
.card-ornament .btn {
  font-size: 1rem;
}

.skin-title {
  font-size: 1.25rem;
}

.card-skin .card-body {
  color: #fff;
}

.white-content .card-skin .card-body,
.white-content .card-ornament .card-body {
  color: inherit;
}

.card-skin .skin-image {
  height: 500px;
  aspect-ratio: 9/16;
}

/* .nav-pills:not(.nav-pills-icons):not(.nav-pills-just-icons) .nav-item .nav-link {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-pills:not(.nav-pills-icons):not(.nav-pills-just-icons) .nav-item .nav-link .badge {
  margin-bottom: 0;
  margin-left: 5px;
} */
.card-ornament,
.card-coin {
  flex: 1 0 250px;
  margin: 1rem 0.25em;
  align-items: center;
}

.nav-shop-items .badge {
  margin-bottom: -5px;
}

.card-ornament .card-body {
  color: #fff;
}

.card-ornament .ornament-image {
  height: 100px;
}

.btn.disabled {
  cursor: not-allowed;
  pointer-events: inherit;
}

.btn-block {
  min-height: 46px;
}

.nav-shop-items .nav-item a {
  border-radius: 5px !important;
}

.carousel-item-skin {
  margin: 0 1rem;
}

.carousel-item-skin .btn-block {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin: 0;
}

.skin-rotation-item {
  border-radius: 1rem;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  min-height: 250px;
}

.rotation-block {
  position: absolute;
  bottom: 0;
  margin: 0;
}

.rotation-name {
  background-color: rgba(33, 37, 41, 0.8);
  color: #fff;
  padding: 0.5rem 0;
}

.modal.show .modal-dialog.skin-preview,
.modal.show .modal-dialog.vip-rewards {
  transform: none;
}

.skin-preview .skin-img {
  height: 500px;
}

.modal-open {
  overflow: auto;
}

/* order history */

.table-responsive {
  overflow: auto;
}

/* admin */

.card-user {
  flex: 1 0 300px;
  margin: 1rem 0.25em;
}

.card-user .card-body {
  min-height: 200px;
}

.card-admin-stats {
  flex: 1 0 300px;
  margin: 1rem 0.25em;
}

.card-admin-stats .card-body {
  min-height: 100px;
}

.card-admin-stats .card-footer {
  padding: 1rem 1rem;
}

.card-admin-stats .card-title {
  display: flex;
  align-items: center;
}

.card-admin-stats .card-title .coin {
  margin-right: 0.5rem;
}
.card-premium-icon {
  width: 35px;
  height: 35px;
  background-size: cover;
  position: absolute;
  top: 15px;
  right: 10px;
}

.ring-container {
  z-index: 10;
}

/* end admin */

/* Light theme */

/* 
.white-content .card-footer .pack-price {
  border-color: #222a42;
} */

.white-content .shop-title {
  color: #fff !important;
}

.white-content .pack-spotlight a {
  color: #525f7f;
}

.white-content .tooltip-inner {
  border: 1px solid #222a42;
  color: #222a42;
}

.white-content .bs-tooltip-right .arrow::before,
.white-content .bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #222a42;
}

.vip-avatar {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  /* overflow: hidden; */
}

.vip-level {
  flex: 1;
}

.vip .reward-case.reward-current,
.vip .reward-case.reward-unlock,
.vip .accordion-button {
  cursor: pointer;
}

.vip .reward-case.reward-past {
  cursor: default;
}

.vip .reward-case {
  cursor: not-allowed;
}

.vip .reward-case {
  flex: 1 0 10%;
  border: 1px solid #525f7f;
  align-items: center;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 0.3rem;
}

.vip .reward-case .reward-body {
  min-height: 60px;
  min-width: 60px;
}

.reward-current {
  box-shadow: inset 0px 0px 20px 0px #ffffff7a;
}

.reward-unlock {
  box-shadow: inset 0px 0px 20px 3px #00f2c3;
  -webkit-animation: blink 1.5s infinite alternate;
  animation: blink 1.5s infinite alternate;
}

/* .reward-unlock:hover {
  box-shadow: inset 0px 0px 20px 10px #00f2c3;
} */

.reward-past {
  box-shadow: inset 0px 0px 20px 5px #000000ab;
}

@-webkit-keyframes blink {
  100% {
    box-shadow: inset 0px 0px 20px 15px #00f2c3;
  }
  0% {
    box-shadow: inset 0px 0px 20px 10px #00f2c3;
  }
}

@keyframes blink {
  100% {
    box-shadow: inset 0px 0px 20px 15px #00f2c3;
  }
  0% {
    box-shadow: inset 0px 0px 20px 10px #00f2c3;
  }
}

.modal-content {
  background-color: transparent;
}

.vip-rewards .coin {
  height: 100%;
  margin: 0 0.5rem;
}

.modal-dialog-scrollable .modal-content {
  overflow: auto;
}

.brtl-0,
.brt-0,
.br-0 {
  border-top-left-radius: 0;
}

.brtr-0,
.brt-0,
.br-0 {
  border-top-right-radius: 0;
}

.brbl-0,
.brb-0,
.br-0 {
  border-bottom-left-radius: 0;
}

.brbr-0,
.brb-0,
.br-0 {
  border-bottom-right-radius: 0;
}

.flex-1 {
  flex: 1;
}

button.price {
  font-size: 1.5rem;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.monthly-pass .alert {
  margin-bottom: 0;
}

.plan {
  padding: 0.75rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin: 0.25rem 0;
}

.plan .plan-title {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.25;
  margin-bottom: 0.25rem;
}

.plan .plan-price {
  line-height: 1;
  font-weight: bold;
}

.plan .plan-price .text-muted {
  font-size: 0.8rem;
}

.plan.active,
.plan:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.plan.active {
  border-left: 2px solid #1d8cf8;
}

.plan-selector hr {
  border-top: 1px solid rgb(255 255 255 / 15%);
}

.plan-selector .plan-total {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.25;
}
.plan-selector .plan-total .text-muted {
  font-size: 1rem;
}

.progress-bar-striped {
  background-image: linear-gradient(
    315deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
}

.progress-bar-animated {
  animation-direction: reverse;
}

.badge.inactive {
  filter: opacity(0.5);
}

.card-stats .row > div {
  padding: 0 1rem;
}

.card-stats .row > div:nth-child(2) {
  flex: 1;
}

.login-history {
  display: inline-flex;
  align-items: center;
}

.btn.btn-default,
.btn.btn-default:hover,
.btn.btn-default:active,
.btn.btn-default:focus {
  background: linear-gradient(93.52deg, #5865f2 -0.28%, #e14eca 131.56%) !important;
}

#navbar-home {
  background: transparent;
  position: absolute;
}

#navbar-home .navbar-nav {
  align-items: center;
}
#navbar-home .nav-item a.nav-link:hover {
  color: inherit;
  opacity: 0.8;
}

#navbar-home .premium-link {
  display: flex;
}

.premium-link {
  color: #feca19 !important;
}

#navbar-home {
  font-size: 1rem;
}

#navbar-home .badge-p1 {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  background-size: cover;
}

#navbar-home img.lang {
  max-width: fit-content;
}

#navbar-home li {
  text-align: center;
}
@media screen and (max-width: 767px) {
  #navbar-home {
    background-color: #11111b;
  }
  .login-link {
    display: block !important;
  }
}

.login-link {
  display: none;
}

.navbar .btn {
  margin-right: 0;
}

#navbar-home .btn {
  color: #fff;
  border: 0;
  padding: 0.5rem 2rem;
}

.lang:hover {
  opacity: 0.8;
}

.lang.inactive {
  opacity: 0.5;
}

.App-header {
  background: linear-gradient(180deg, rgba(30, 30, 47, 0.1) 0%, #1e1e2f 100%);
  min-height: calc(100vh - 5rem);
  overflow: hidden;
  padding-top: 5rem;
  position: relative;
}

.App-header .character {
  padding-top: 5rem;
  min-height: calc(100vh - 5rem);
  overflow-y: hidden;
  align-items: center;
  display: flex;
}

.App-header .App-description,
.App-header .row {
  /* height: calc(100vh - 10rem); */
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  align-items: center;
  font-size: 1rem;
  z-index: 1;
}

.bg-images {
  position: absolute;
  min-height: 100%;
  z-index: -1;
  object-fit: cover;
}

.bg-background {
  top: 0;
  left: 0;
  width: 100%;
  -ms-transform: scale(1.025); /* IE 9 */
  -webkit-transform: scale(1.025); /* Safari 3-8 */
  transform: scale(1.025);
}

img {
  /* height: fit-content; */
}

.bg-character {
  bottom: -75px;
  right: 0;
  height: calc(100% - 5rem);
  z-index: 1;
  filter: drop-shadow(10px 4px 4px rgba(0, 0, 0, 0.25));
  pointer-events: none;
  min-height: calc(100% - 5rem);
}

@media screen and (max-width: 991px) {
  .bg-character {
    display: none;
  }
  .App-description .add-buttons,
  .App-description h1,
  .App-description h2,
  .App-header h1,
  .App-header h2,
  .App-header h3 {
    text-align: center;
  }
  footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  section {
    padding: 1rem 2rem !important;
  }
  section.main-features {
    justify-content: center;
  }
}

.App-description .universes-list {
  justify-content: space-evenly;
}
@media screen and (min-width: 1400px) {
  .bg-character {
    right: 8%;
  }
}

@media screen and (max-width: 1199px) {
  .plans {
    flex-direction: column;
  }
  .plans .plan {
    border-width: 1px !important;
    margin: 1rem 0;
  }
  .plans .plan.highlighted {
    min-height: 400px !important;
  }
  .servers-list img:nth-child(even) {
    display: none;
  }
}

.main-features img {
  object-fit: cover;
  margin-bottom: 2rem;
}

.App-description li {
  padding-left: 7px;
  font-size: 1.5rem;
}

.App-description li::marker,
.plans .plan li::marker {
  content: url('assets/img/emojis/check.svg');
}

.App-description h1 {
  font-size: 5rem;
  font-weight: bold;
  background: linear-gradient(90deg, #ffffff 0%, #bbc1ff 66%, #f9b3ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  max-width: fit-content;
}

h1,
h2,
h3,
p {
  color: #fff;
}

.App-description h2 {
  margin-bottom: 0;
}

.App-description .universes-list {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.App-description .universes-list img:first-child {
  margin-left: 0;
}
.App-description .universes-list img {
  margin: 0 0.5rem;
}

.App-description .add-buttons {
  margin-bottom: 3rem;
}

.App-description .add-buttons .btn:first-child {
  /* margin-left: 0; */
}

.App-description .add-buttons .btn {
  margin: 0.5rem 1rem;
  font-size: 1.5rem;
}

.App-description .titles {
  margin-bottom: 5rem;
}

.section-separator {
  background-color: #11111b;
  min-height: 135px;
  z-index: 2;
  display: flex;
  align-items: center;
  position: relative;
}

.section-separator .title {
  padding: 1rem;
  align-items: center;
}

.section-separator .title h2 {
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: bold;
}

.servers-list {
  display: flex;
  justify-content: space-evenly;
}

.servers-list img {
  width: 64px;
  height: 64px;
  border-radius: 10px;
}

.main-features {
  padding-top: 5rem;
}

.main-features h3 {
  font-size: 3rem;
  font-weight: bold;
}

section {
  display: flex;
  padding: 1rem 5rem;
  background-color: #1e1e2f;
  position: relative;
  z-index: 1;
  width: 100%;
}

section p {
  font-size: 1rem;
}

.reverse-col {
  flex-direction: row-reverse;
}

.main-features .col-lg-6 {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-wrapper {
  overflow-x: clip;
}

.App-header.premium-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.premium-header section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.premium-header section.titles h1 {
  text-align: center;
}

.plans {
  display: flex;
  align-items: center;
  min-height: 550px;
}

.plans .plan {
  background-color: #27293d;
  background: #27293d;
  border-style: solid;
  border-color: #5865f2;
  border-width: 1px;
  border-radius: 5px;
  box-shadow: 0px 0px 64px -24px rgba(88, 101, 242, 0.8);
  min-height: 400px;
  min-width: 370px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
}
.plans .plan:first-child {
  border-width: 1px 0px 1px 1px;
  border-radius: 5px 0px 0px 5px;
}

.plans .plan:last-child {
  border-width: 1px 1px 1px 0px;
  border-radius: 0px 5px 5px 0px;
}

.plans .plan.highlighted {
  position: relative;
  min-height: 500px;
  min-width: 370px;
  box-shadow: 0px 10px 64px -24px #e14eca, 0px -10px 64px -24px #5865f2;
  border: 0;
  z-index: 1;
  border-color: #5865f2;
}

.plans .plan:nth-child(2) {
  border-radius: 0;
}

.plans .plan:focus-visible,
.plans .plan:visited,
.plans .plan:focus,
.plans .plan:focus-within,
.plans .plan:active,
.plans .plan:target {
  outline: none;
}

.plans .plan.highlighted::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 2px;
  background: linear-gradient(180deg, #5865f2 0%, #e14eca 100%);
  -webkit-mask: linear-gradient(#5865f2 0 0) content-box, linear-gradient(#5865f2 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.plans .plan .popular-pill {
  position: absolute;
  top: 0;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  background: linear-gradient(90deg, #5865f2 0%, #e14eca 100%);
  border-radius: 15px;
  padding: 4px 25px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plans .plan .premium-image {
  width: 32px;
  height: 32px;
  margin-top: 0.5rem;
}

.titles img {
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
}

.titles h1 {
  margin-bottom: 0;
}

.titles > div {
  display: flex;
  align-items: center;
}

.plans .plan h3,
.plans .plan h4 {
  margin-bottom: 0;
}

.plans .plan .plan-price {
  font-size: 2.2rem;
  font-weight: bold;
}

.plans .plan hr {
  border-top: 1px solid #4d4d4d;
  width: 100%;
}

.plans .plan li {
  font-size: 1rem;
  line-height: 1.75;
}

.premium-header h1 {
  font-size: 5rem;
  font-weight: bold;
  background: linear-gradient(94.51deg, rgba(88, 101, 242, 0.4) 53.55%, rgba(225, 78, 202, 0.4) 105.89%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  max-width: fit-content;
}

.premium-header h2 {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.premium-header .text-muted {
  color: #9c9c9c !important;
}

.premium-header h4 {
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 0.5rem !important;
}

.btn.btn-link {
  color: #e14eca;
}

.plans .plan ul {
  margin-bottom: 0;
}

.plans .plan .become-premium {
  text-align: center;
  margin-top: auto;
  margin-bottom: 0.5rem;
}

#pricing-table tr td:last-child {
  border-left: 3px solid rgba(88, 101, 242, 1);
  border-right: 3px solid rgba(88, 101, 242, 1);
}

#pricing-table tbody tr:last-child td {
  padding: 0;
}

#pricing-table tbody tr:last-child td .btn {
  padding: 1rem 1.5rem;
  border-radius: 0;
  border-width: 0px;
}

#pricing-table thead tr th {
  color: #fff;
}

#pricing-table thead tr th:first-child {
  font-size: 1.5rem;
}

#pricing-table thead tr th:not(:first-child) {
  font-size: 1rem;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-transform: none;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

#pricing-table thead tr th:not(:first-child):first-child {
  border-top-left-radius: 5px;
}

#pricing-table thead tr th:not(:first-child):last-child {
  border-top-right-radius: 5px;
  background-color: #5865f2;
  border: 3px solid rgba(88, 101, 242, 1);
}
#pricing-table tr:last-child td:last-child {
  border-bottom: 3px solid rgba(88, 101, 242, 1);
}
#pricing-table tbody th {
  color: #9c9c9c;
  font-weight: normal;
}

#pricing-table tbody td {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

#pricing-table img {
  max-width: 24px;
  max-height: 24px;
}

#pricing-table th {
  font-size: 1rem;
}

footer {
  background-color: #11111b;
  padding: 2rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 5rem;
}

footer .footer-menu {
  display: flex;
  text-align: right;
}
.socials {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0;
}

.socials svg {
  width: 32px;
  height: 32px;
  margin: 0 0.5rem;
}

.patreon {
  background-color: #f96753;
  border-radius: 50%;
  padding: 5px;
}

footer a {
  color: #9c9c9c;
  margin: 0 0.5rem;
}

footer a:first-child {
  margin-left: 0;
}

footer a:last-child {
  margin-right: 0;
}

.terms {
  flex-direction: column;
  padding-top: 5rem;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.collapsing + .login-link {
  display: none !important;
}

.login-link > a {
  padding-top: 0;
  padding-bottom: 0;
}
